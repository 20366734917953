import styled from '@emotion/styled';

export const Center = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.bgBase};
`;

export const TabsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg};
  padding-left: 24px;
`;
