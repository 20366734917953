import dayjs from 'dayjs';
import { type FC, type PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { FilterContextProvider } from '@volvo/vce-package-site-uikit';
import mapI18n from '../../i18n';
import { UnassignedLoadTicketsContent } from './UnassignedLoadTickets';
import { FederationUikitWrapper } from './wrapper/Wrapper';

export * from './filter/Filter';

export type Props = { siteId: string; companyId?: string; pageSize?: number };

export const UnassignedLoadTickets: FC<PropsWithChildren<Props>> = ({
  siteId,
  companyId,
  pageSize = 30,
  children,
}) => (
  <FederationUikitWrapper>
    <FilterContextProvider defaultStartDate={dayjs().subtract(7, 'day')} defaultEndDate={dayjs()}>
      <I18nextProvider i18n={mapI18n}>
        <UnassignedLoadTicketsContent
          siteId={siteId}
          companyId={companyId}
          pageSize={pageSize < 100 ? pageSize : 100}
        >
          {children}
        </UnassignedLoadTicketsContent>
      </I18nextProvider>
    </FilterContextProvider>
  </FederationUikitWrapper>
);
