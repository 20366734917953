import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import { Container } from './styles';

export const Header: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Typography variant="heading2">{t('load-tickets.page-title')}</Typography>
    </Container>
  );
};
